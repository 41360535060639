import React, {Component} from 'react'
import styled from 'styled-components'
import {connect} from 'react-redux';
import { startAudio, setAudioFile, stopAudio, resumeAudio, getAudioFile } from '../actions/website';
import { fetchArtists } from '../actions/artists';
import SEO from '../components/seo'
import {Link} from 'gatsby';
import FacebookButton from '../components/buttons/facebook';
import TwitterButton from '../components/buttons/twitter';
import InstagramButton from '../components/buttons/instagram';
import PauseButton from '../components/buttons/pause';
import StopButton from '../components/buttons/stop';
import PlayButton from '../components/buttons/play';
import classNames from 'classnames';
import { tablet, desktop } from '../breakpoints';
import { graphql } from "gatsby"

//load artist images
import Images from '../components/artistImages'


class ArtistPage extends Component {

	constructor(){
		super();
		this.state = {
			playing: false,
			paused: false,
			animatorEffect: 0,
			
		}
	}

	componentDidMount(){

		var slider = new window.MasterSlider();
		slider.setup('masterslider' , {
				 width:800,    
				 height: 500,  
				 space:0,
				 view: 'mask',
				 layout: 'fillwidth',
				 autoHeight: true,
				 grabCursor: false,
				 mouse:false,
				 autoplay: true,
				 loop: true
		});


		this.props.fetchArtists();
	
		if(this.props.audio.audio_file === this.props.data.artist.slug){
			this.setState({
				playing:true
			})
		}

		if(this.props.audio.audio_file === this.props.data.artist.slug && this.props.audio.audio_state == "STOPPED"){
			this.setState({
				pasued:true
			})
		}

	}

	
	componentWillUnmount(){
		clearInterval(this.timer);
	}

	/*
    |--------------------------------------------------------------------------
    | watch for prop updates
    |--------------------------------------------------------------------------
    */
   	componentWillReceiveProps(next){
		if(next.artists !== this.props.artists) {
			this.renderAllArtists();
		}
	}

	renderGallery() {
		
		const photos = JSON.parse(this.props.data.artist.photos);
		
		return photos.map((pic,i) => {
			const slug = pic.replace(/-/g, '').replace(/.jpg/g, '');

			return (
				<div key={i} className="ms-slide">
					<img src={Images[slug]} />
				</div>
			)
		})
		
	}

	renderAllArtists() {


		if(!this.props.artists || !Array.isArray(this.props.artists)) return;
		
		return this.props.artists.map((artist,i) => {

			const selected = artist.slug === this.props.data.artist.slug ? 'active' : '';
			const url = `/artist/${artist.slug}`;

			return (
				<li className={selected} key={i} ><Link to={url}>{artist.name}</Link></li>
			)
		}) 
		
	}

	stopDemo() {
		this.props.stopAudio();
		this.setState({ playing: false, paused:false });
	}

	playDemo() {
		this.props.setAudioFile(this.props.data.artist.slug);
		this.props.startAudio();
		this.setState({ playing: true });
	}

	pauseDemo() {
		this.props.stopAudio();
		this.setState({ paused: true });
	}

	unPauseDemo(){
		this.props.resumeAudio();
		this.setState({ paused: false });
	}


	render() { 

		const artist = this.props.data.artist;
		const btnPlayDemo = classNames('button-playdemo', { show: !this.state.playing });
		const btnStop = classNames('button-stop', { show:this.state.playing });
		const btnPause = classNames('button-pause', { show: this.state.playing, hide: this.state.paused });
		const btnPlay = classNames('button-play', { show: this.state.paused });
		const contact = `mailto:carl@theradiodepartment.com?subject=Enquiry - ${artist.name}`;

		return (
			<Page>

				<SEO title={artist.name} keywords={[`${artist.name}`, `The Radio Department`]} />

				<Wrapper>
					<Title>{artist.name}<span>{artist.showname}</span></Title>

					<Inner>
						<Container>
							<Left>
									
							<div className="galleryWrapper">
										<div className="master-slider ms-skin-default" id="masterslider">
													{this.renderGallery()}									
										</div>
							</div>

							</Left>
							<Right >
								<Divider/>
								<div dangerouslySetInnerHTML={{ __html: artist.biog }}></div>

								<ul className="meta" >
									<li><a href={contact}><button className="btn-enquire">Enquire</button></a></li>
									<li className={btnPlayDemo}><button onClick={this.playDemo.bind(this)} className="btn-playdemo">Play demo</button></li>
									<li className={btnStop} ><button className="btn-stop" onClick={this.stopDemo.bind(this)}><StopButton/></button></li>
									<li className={btnPause}><button className="btn-pause" onClick={this.pauseDemo.bind(this)}><PauseButton/></button></li>
									<li className={btnPlay}><button className="btn-play" onClick={this.unPauseDemo.bind(this)}><PlayButton/></button></li>
									{ artist.facebook ? <li><a href={artist.facebook} target="_blank"><button className="btn-fb"><FacebookButton/></button></a></li> : null }
									{ artist.twitter ? <li><a href={artist.twitter} target="_blank"><button className="btn-twitter"><TwitterButton/></button></a></li> : null }
									{ artist.instagram ? <li><a href={artist.instagram} target="_blank"><button className="btn-instagram"><InstagramButton/></button></a></li> : null }
								</ul>
							</Right>
						</Container>

						<MoreArtsts>
								<Divider/>
								<ul>{this.renderAllArtists()}</ul>
						</MoreArtsts>
					</Inner>
					
				</Wrapper>
				
			</Page>
		)

  }

}

function mapStateToProps(state){
	return { 
		artists: state.artists.artists,
		audio: {
			audio_state: state.website.audio_state,
			audio_file: state.website.audio_file
		}
	}
}

export default connect(mapStateToProps, { startAudio, setAudioFile, stopAudio, fetchArtists, resumeAudio, getAudioFile })(ArtistPage);


/*
|--------------------------------------------------------------------------
| Styles
|--------------------------------------------------------------------------
*/
const Page = styled.section`
	min-height:100%;
`

const Wrapper = styled.section`
	min-height:100%;
	padding-top:100px;
	background:rgb(253, 247, 244);
`

const Title = styled.h1`
	font-size: 7vw;
	line-height:1em;
    letter-spacing:0.01em;
	margin:0;
	margin-bottom:30px;
	font-weight:normal;
	text-align:center;

	span {
		display: block;
		font-size: .4em;
	}

	@media only screen and (max-width: ${tablet}) {
		font-size:40px;
		padding:0 40px;
		margin-bottom:0;
	 }

	 @media only screen and (min-width: ${desktop}) {
		font-size:100px;
	 }
`

const Container = styled.section`
	padding:20px;
	min-height:100%;
	display:flex;

	@media only screen and (max-width: ${tablet}) {
		flex-flow: column-reverse;
		padding-bottom:0;
	 }
`

const Inner = styled.div`
	max-width:1100px;
	width:100%;
	margin:auto;
`

const Left = styled(Container)`
	margin-right: auto;
	width:50%;
	padding-right:5%;
	position:relative;
	display:block;
	
	@media only screen and (max-width: ${tablet}) {
		width:100%;
		min-height:500px;
	 }

	 .master-slider {
		 img {
			 max-width:100%;
		 }
	 }
	
	 .galleryWrapper {
		margin-top:10px;
	 }

`

const Right = styled(Container)`
	margin-left: auto;
	display:block;
	width:50%;
	color:#000;
    font-size:22px;
	line-height:1.6em;	

	@media only screen and (max-width: ${tablet}) {
		width:100%;
		font-size:18px;
	 }	 

	a {
		color: #000;
		text-decoration: none;
		
		&:hover {
			text-decoration: underline;
		}
	}

	ul.meta {
		display:flex;
		flex-direction: row;
		align-items: center;
		padding:0;
		justify-content: flex-start;
		margin-top:40px;

		li {
			list-style:none;
			margin:0;
			padding:0;
			display:inline-block;

			svg {
				width:16px;
				height:16px;
				fill:#000;
			}
		}

		li:nth-child(3), li:nth-child(4), li:nth-child(5), li:nth-child(6), li:nth-child(7), li:nth-child(8)  {
			button {
				border-radius:50%;
				border:1px solid #000;
				width:35px;
				height:35px;
				text-align:center;
			}
		}

		li.button-stop, li.button-pause, li.button-play, li.button-playdemo, li.button-play {
			display:none;
		}

		li.show {
			display:block;
		}

		li.hide {
			display:none !important;
		}

		button.btn-fb {
			svg {
				position:relative;
				left:-1px;
			}
		}


		button.btn-play {
			svg {
				font-size: 14px;
				height: 14px;
				position: relative;
				left: 1px;
				top: -1px;

				@media only screen and (max-width: ${tablet}) {
					left: 1px;
					top: 1px;
				}
				
			}
		}

		button.btn-stop {

			svg {
				width: 12px;
				height: 12px;
				position: relative;
				top: -2px;
				left: 0;

				@media only screen and (max-width: ${tablet}) {
					top:2px;
					left:-1px;
				}
			}
		}

		button.btn-pause {
			svg {
				width: 14px;
				height: 14px;
				position: relative;
				left: 0px;
				top: -1px;

				@media only screen and (max-width: ${tablet}) {
					left: 0px;
    				top: 1px;
				}
				
			}
		}

	}

	button {
		border:1px solid #000;
		border-radius:30px;
		background:transparent;
		padding:9px;
		width:100px;
		font-size:16px;
		margin-right: 15px;
		outline:none;

		&:hover {
			background: #000;
			color:#F7B295;
			cursor:pointer;
			
			svg {
				fill:#F7B295 !important;
			}
		}

		@media only screen and (max-width: ${tablet}) {
			padding: 9px;
			width: 80px;
			font-size: 12px;
			margin-right: 10px;
		}
		
	}
`

const Divider = styled.hr`
	border:none;
	border-bottom:2px solid #000;
`

const MoreArtsts = styled.section`
	padding:40px;

	ul {
		margin:0;
		padding:0;
		column-count: 2;
		margin-top:40px;

		li {
			list-style:none;
			margin:0;
			padding:0;
			text-align:center;
			font-size:22px;
			display:block;
			margin-bottom:5px;

			@media only screen and (max-width: ${tablet}) {
				font-size:18px;
			}

			a {
				color:#000;
				text-decoration:none;

				&:hover {
					text-decoration:underline;
				}
			}
		}

		li.active {
			a{ 
				text-decoration:underline;
			}
		}
	}
`



/*
|--------------------------------------------------------------------------
| Queries
|--------------------------------------------------------------------------
*/
export const query = graphql`
  query ArtistDetails($artistId: String) {
    artist(slug: { eq: $artistId }) {
      id
      name
	  biog
			slug
			showname
			audio
			photos
			facebook
			twitter
			instagram
		}
  }
`