import React, { Component } from 'react';
import styled from 'styled-components'

class PauseButton extends Component {
	render(){
		return(
			<Icon version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 303 512" >
                <g>
                    <rect width="102.4" height="512"/>
                    <rect x="200.6" width="102.4" height="512"/>
                </g>
            </Icon>
		)
	}
}

const Icon = styled.svg`
	fill:#f9ad8f;
	width:100%;
	height:100%;
`

export default PauseButton;
