import React, { Component } from 'react';
import styled from 'styled-components'

class StopButton extends Component {
	render(){
		return(
			<Icon version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 226 226" >
                <rect width="226" height="226"/>
            </Icon>
		)
	}
}

const Icon = styled.svg`
	fill:#f9ad8f;
	width:100%;
	height:100%;
`

export default StopButton;
