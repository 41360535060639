import React, { Component } from 'react';
import styled from 'styled-components'

class PlayButton extends Component {
	render(){
		return(
			<Icon version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 280.5 357" >
                <g>
                    <g id="play-arrow">
                        <polygon points="0,0 0,357 280.5,178.5 		"/>
                    </g>
                </g>
            </Icon>
		)
	}
}

const Icon = styled.svg`
	fill:#f9ad8f;
	width:100%;
	height:100%;
`

export default PlayButton;
